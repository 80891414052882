<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">
    <b-modal v-model="showQuestionnairePreview" class="p-6" full-screen>
      <dpt-questionnaire v-if="selectedQuestionnaire" :questionnaire-id="selectedQuestionnaire.id"
                         :isPreview="isPreview"></dpt-questionnaire>
    </b-modal>
    <b-modal :can-cancel="['escape', 'x']" :on-cancel="resetStatus" :active="showImportAnswersDialog" class="is-bg-main p-6">
      <import-questionnaire-answers :questionnaire="selectedQuestionnaire" @close="resetStatus"></import-questionnaire-answers>
    </b-modal>
    <div v-if="!addQuestionnaire">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>Fichas de análisis</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                  <b-button type="is-primary" @click="addQuestionnaire = true">
                    <v-icon icon="plus" class="g-icon"></v-icon>
                    <span>Añadir nueva</span>
                  </b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="block">
            <section class="box">
              <div class="block is-full">
                <b-field grouped group-multiline position="is-right">
                  <b-field>
                    <b-input placeholder="Buscar fichas de análisis..." type="search" icon="search"
                             v-model="filters.questionnaireSearch"></b-input>
                    <b-select v-model="filters.selectedCompany" placeholder="Empresa" :loading="loadingCompany">
                      <option v-for="(company, i) in companyList" :value="company.name" :key="i">{{ company.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-radio-button v-model="filters.active"
                                      name="visible"
                                      native-value="Todos"
                                      type="is-primary is-light">
                        Todos
                      </b-radio-button>
                    </p>
                    <p class="control">
                      <b-radio-button v-model="filters.active"
                                      name="visible"
                                      native-value="Visible"
                                      type="is-primary is-light">
                        <v-icon icon="eye"></v-icon>
                      </b-radio-button>
                    </p>
                    <p class="control">
                      <b-radio-button v-model="filters.active"
                                      name="visible"
                                      native-value="Oculto"
                                      type="is-primary is-light">
                        <v-icon icon="eye-slash"></v-icon>
                      </b-radio-button>
                    </p>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button class="button is-link" @click="fetchQuestionnaire">
                        <v-icon icon="filter" class="g-icon"></v-icon>
                        Filtrar
                      </b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="block m-4">
                <b-table
                  backend-sorting
                  backend-pagination
                  @sort="handleTableSort"
                  :data="data"
                  :hoverable="isHoverable"
                  :loading="loadingQuestionnaire"
                  :current-page.sync="currentPage">
                  <b-table-column centered field="id" label="REF" sortable v-slot="props">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column centered field="name" label="Ficha de análisis" sortable v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column centered field="company.name" label="Compañía" sortable v-slot="props">
                    {{ props.row.company.name }}
                  </b-table-column>
                  <b-table-column centered field="active" label="Activo" sortable v-slot="props">
                    <v-icon :icon="props.row.active ? 'check-circle':'times-circle'" class="pointer"></v-icon>
                  </b-table-column>
                  <b-table-column centered label="Acciones" v-slot="props">
                    <el-tooltip class="item" effect="dark" content="Previsualizar ficha de análisis" placement="top">
                      <el-button size="mini" type="primary" circle plain @click="handlePreviewQuestionnaire(props.row)">
                        <v-icon icon="eye"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Importar respuestas base"
                                placement="top">
                      <el-button size="mini" type="warning" circle plain @click="handleImportAnswers(props.row)">
                        <v-icon icon="file-import"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Exportar respuestas" placement="top">
                      <el-button size="mini" type="info" circle @click="generateReport(props.row)">
                        <v-icon icon="file-export"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                      <el-button size="mini" type="warning" circle @click="handleUpdateQuestionnaire(props.row)">
                        <v-icon icon="pen"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Duplicar" placement="top">
                      <el-button size="mini" type="primary" circle @click="handleDuplicateQuestionnaire(props.row)">
                        <v-icon icon="copy"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                      <el-button size="mini" type="danger" circle @click="handleDeleteQuestionnaire(props.row)">
                        <v-icon icon="trash"></v-icon>
                      </el-button>
                    </el-tooltip>
                  </b-table-column>
                </b-table>
              </div>
              <div class="block">
                <el-pagination class="mt-5"
                               :hide-on-single-page="true"
                               @size-change="handlePageSizeChange"
                               @current-change="handlePageChange"
                               :current-page.sync="currentPage"
                               :page-sizes="[20, 50, 100, 200]"
                               :page-size="20"
                               layout="total, sizes, prev, pager, next"
                               :total="totalElements">
                </el-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div id="addQuestionnaire" v-if="addQuestionnaire">
      <add-questionnaire @finishedAddingQuestionnaire="handleQuestionnaireAdded" :is-editing="isEditing"
                         :questionnaire="selectedQuestionnaire"></add-questionnaire>
    </div>
    <b-loading class="report-loading" v-if="generatingReport" is-full-page :v-model="generatingReport"
               :active="generatingReport"
               :can-cancel="false">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <h2 class="title is-2" style="color:black !important;">Generando Informe</h2>
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin">
        </b-icon>
      </div>
    </b-loading>
  </section>
</template>

<script>
import AddQuestionnaire from '@/components/questionnaire/AddQuestionnaire'
import DptQuestionnaire from '@/components/questionnaire/DptQuestionnaire'
import ImportQuestionnaireAnswers from '@/components/questionnaire/ImportQuestionnaireAnswers'
import { mapGetters } from 'vuex'
import * as types from '@/store/types'
import FileSaver from 'file-saver'

export default {
  components: { AddQuestionnaire, DptQuestionnaire, ImportQuestionnaireAnswers },
  data () {
    return {
      addQuestionnaire: false,
      currentPage: 1,
      data: [],
      companyList: [],
      filters: {
        questionnaireSearch: '',
        selectedCompany: null,
        active: 'Todos'
      },
      generatingReport: false,
      isEditing: false,
      isHoverable: true,
      isPreview: false,
      loadingCompany: false,
      loadingQuestionnaire: false,
      pageSize: 20,
      rangeBefore: 3,
      rangeAfter: 1,
      selectedQuestionnaire: null,
      showQuestionnairePreview: false,
      sortOption: 'id,desc',
      totalElements: null,
      showImportAnswersDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  },
  created () {
    this.fetchCompanies()
    this.fetchQuestionnaire()
  },
  methods: {
    fetchCompanies () {
      this.loadingCompany = true
      this.axios.get(process.env.VUE_APP_API_GET_COMPANY, {
        params: {
          page: 0,
          size: 9999
        }
      }).then(response => {
        this.companyList = response.data.content
      }).catch(err => {
        console.error('No se pueden obtener las empresas', err)
      }).finally(() => {
        this.loadingCompany = false
      })
    },
    fetchQuestionnaire () {
      this.loadingQuestionnaire = true
      this.axios.get(process.env.VUE_APP_API_GET_QUESTIONNAIRE_SUMMARY, {
        params: {
          page: this.currentPage - 1,
          size: this.pageSize,
          userId: this.user.id,
          sort: this.sortOption,
          searchQuery: this.getSearchQuery()
        }
      }).then(response => {
        this.totalElements = response.data.totalElements
        this.data = response.data.content
      }).catch(err => {
        console.error('No se pueden obtener los cuestionarios', err)
      }).finally(() => {
        this.loadingQuestionnaire = false
      })
    },
    generateReport (questionnaire) {
      this.generatingReport = true
      this.selectedQuestionnaire = questionnaire
      this.axios.get(process.env.VUE_APP_API_GENERATE_REPORT, {
        responseType: 'blob',
        params: {
          questionnaireId: this.selectedQuestionnaire.id,
          userId: this.user.id
        }
      }).then(response => {
        const filename = response.headers.filename
        const report = response.data
        FileSaver.saveAs(report, filename)
      }).catch(err => {
        this.generatingReport = false
        console.error('Error en la generación del informe', err)
        this.$notify.error({
          title: 'Error',
          message: 'No se ha podido generar el informe'
        })
      }).finally(() => {
        this.generatingReport = false
      })
    },
    getSearchQuery () {
      let searchQuery = ''
      if (this.filters.questionnaireSearch) {
        searchQuery += 'name$' + this.filters.questionnaireSearch
      }
      if (this.filters.selectedCompany) {
        searchQuery += ',company.name:' + this.filters.selectedCompany
      }
      if (this.filters.active !== 'Todos') {
        searchQuery += ',active:' + (this.filters.active === 'Visible')
      }
      return searchQuery
    },

    handleDeleteQuestionnaire (questionnaire) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Cuestionario',
        message: '¿Está seguro de que quiere <b>eliminar</b> el cuestionario? Esto eliminará también todas las respuestas que se hayan hecho sobre éste',
        confirmText: 'Eliminar cuestionario',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_QUESTIONNAIRE, {
            params: {
              questionnaireId: questionnaire.id
            }
          }).then(() => {
            this.fetchQuestionnaire()
            this.$notify.success({
              title: 'Cuestionario eliminado',
              message: 'El cuestionario se ha eliminado correctamente'
            })
          }).catch(err => {
            console.error('No se puede eliminar la pregunta', err)
          })
        }
      })
    },
    handleDuplicateQuestionnaire (questionnaire) {
      this.$confirm('¿Está seguro/a de que desea duplicar la ficha de análisis?', 'Atención', {
        confirmButtonText: 'Duplicar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.axios.post(process.env.VUE_APP_API_DUPLICATE_QUESTIONNAIRE, null, {
          params: {
            questionnaireId: questionnaire.id
          }
        }).then(() => {
          this.fetchQuestionnaire()
          this.$notify.success({
            title: 'Ficha de análisis duplicada',
            message: 'La ficha de análisis se ha duplicado correctamente'
          })
        }).catch(err => {
          console.log('No se puede duplicar la ficha de análisis', err)
        })
      })
    },
    handleImportAnswers (questionnaire) {
      this.selectedQuestionnaire = questionnaire
      this.showImportAnswersDialog = true
      // TODO Show dialog for downloading template
      // TODO
    },
    handlePageChange (page) {
      this.currentPage = page
      this.fetchQuestionnaire()
    },
    handlePageSizeChange (pageSize) {
      this.pageSize = pageSize
      this.currentPage = 1
      this.fetchQuestionnaire()
    },
    handlePreviewQuestionnaire (questionnaire) {
      this.isPreview = true
      this.selectedQuestionnaire = questionnaire
      this.showQuestionnairePreview = true
    },
    handleQuestionnaireAdded () {
      this.fetchQuestionnaire()
      this.resetStatus()
    },
    handleSelectCompany (value) {
      this.filters.selectedCompany = value
    },
    handleTableSort (field, order) {
      this.sortOption = field + ',' + order
      this.fetchQuestionnaire()
    },
    handleUpdateQuestionnaire (questionnaire) {
      this.isEditing = true
      this.selectedQuestionnaire = questionnaire
      this.addQuestionnaire = true
    },
    resetStatus () {
      this.addQuestionnaire = false
      this.isEditing = false
      this.selectedQuestionnaire = null
      this.isPreview = false
      this.sortOption = 'id,desc'
      this.showImportAnswersDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.report-loading ::v-deep .loading-background {
  background: rgba(52, 51, 51, 0.9) !important;
}

</style>
